import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SectionName } from "../App";

interface NavItemProps {
  section: SectionName | "privacy" | "driver-setup" | "account";
  onClick: (section: SectionName | "privacy" | "driver-setup" | "account") => void;
  children: React.ReactNode;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ section, onClick, children, isActive }) => (
  <button
    onClick={() => onClick(section)}
    className={`px-3 py-2 rounded-md text-sm font-medium cursor-pointer transition-colors duration-200 
      ${isActive ? "bg-[#1B3160] text-white" : "text-gray-300 hover:bg-[#1B3160]/80 hover:text-white"}`}
  >
    {children}
  </button>
);

interface NavigationProps {
  scrollToSection: (section: SectionName) => void;
}

const Navigation: React.FC<NavigationProps> = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<SectionName | "privacy" | "driver-setup" | "account">("home");
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const AdminDashboardItem: React.FC = () => {
  //   return (
  //     <NavItem section="dashboard" onClick={handleNavClick} isActive={activeSection === "dashboard"}>
  //       DASHBOARD
  //     </NavItem>
  //   );
  // };

  const handleNavClick = (section: SectionName | "privacy" | "driver-setup" | "account" | "dashboard") => {
    if (section === "privacy") {
      navigate("/privacy-notice");
      setActiveSection("privacy");
    } else if (section === "driver-setup") {
      navigate("/driver-setup");
      setActiveSection("driver-setup");
    } else if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scrollToSection(section as SectionName);
        setActiveSection(section as SectionName);
      }, 100);
    } else {
      scrollToSection(section as SectionName);
      setActiveSection(section as SectionName);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (location.pathname === "/privacy-notice") {
      setActiveSection("privacy");
    } else if (location.pathname === "/driver-setup") {
      setActiveSection("driver-setup");
    } else if (location.pathname === "/account" || location.pathname === "/login" || location.pathname === "/register") {
      setActiveSection("account");
    } else {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sections = document.querySelectorAll("section");

        sections.forEach((section) => {
          const sectionTop = section.offsetTop - 64;
          const sectionBottom = sectionTop + section.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            const currentSection = section.id as SectionName;
            if (currentSection !== activeSection) {
              setActiveSection(currentSection);
            }
          }
        });
      };

      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [activeSection, location.pathname]);

  const navItems: SectionName[] = ["home", "about", "services", "contact", "testimonials", "careers"];

  return (
    <nav className="bg-gradient-to-r from-[#1B3160] to-[#0F1E3C] fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <NavLink to="/" className="flex-shrink-0">
              <img src="logo.png" className="max-w-28" alt="Princeton Transport LLC" />
            </NavLink>
            <div className="hidden lg:block">
              <div className="ml-10 flex items-center space-x-4">
                {navItems.map((item) => (
                  <NavItem key={item} section={item} onClick={handleNavClick} isActive={activeSection === item}>
                    {item.toUpperCase()}
                  </NavItem>
                ))}
                <NavItem section="driver-setup" onClick={handleNavClick} isActive={activeSection === "driver-setup"}>
                  DRIVER SETUP
                </NavItem>

                {/* <AdminDashboardItem /> */}
              </div>
            </div>
          </div>
          <div className="flex items-center lg:hidden">


            {/* Mobile menu button */}
            <button
              onClick={toggleMenu}
              type="button"
              className="ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-300 
                hover:text-white hover:bg-[#1B3160]/80 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-offset-[#1B3160] focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`lg:hidden ${isOpen ? "block" : "hidden"}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col bg-[#1B3160]/90 backdrop-blur-sm">
          {navItems.map((item) => (
            <NavItem key={item} section={item} onClick={handleNavClick} isActive={activeSection === item}>
              {item.toUpperCase()}
            </NavItem>
          ))}
          {/* <NavItem 
            section="privacy" 
            onClick={handleNavClick} 
            isActive={activeSection === "privacy"}
          >
            PRIVACY NOTICE
          </NavItem> */}
          <NavItem section="driver-setup" onClick={handleNavClick} isActive={activeSection === "driver-setup"}>
            CARRIER SETUP
          </NavItem>

          {/* <AdminDashboardItem /> */}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
