import React, { useState, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';

interface FormData {
  companyName: string;
  dba: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  email: string;
  mc: string;
  usdot: string;
  feinSsn: string;
  numberOfTrucks: string;
  numberOfDrivers: string;
  factorInvoices: string;
  preferredStates: string;
  documents: FileList | null;
}

interface FormErrors {
  companyName?: string;
  email?: string;
  phone?: string;
  mc?: string;
  usdot?: string;
  feinSsn?: string;
  documents?: string;
}

const CarrierSetup: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    companyName: '',
    dba: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
    mc: '',
    usdot: '',
    feinSsn: '',
    numberOfTrucks: '',
    numberOfDrivers: '',
    factorInvoices: 'no',
    preferredStates: '',
    documents: null
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    
    if (!formData.companyName.trim()) {
      newErrors.companyName = 'Company name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone.replace(/\D/g, ''))) {
      newErrors.phone = 'Phone number is invalid';
    }

    if (!formData.mc.trim()) {
      newErrors.mc = 'MC number is required';
    }

    if (!formData.usdot.trim()) {
      newErrors.usdot = 'USDOT number is required';
    }

    if (!formData.feinSsn.trim()) {
      newErrors.feinSsn = 'FEIN/SSN is required';
    }

    if (!formData.documents || formData.documents.length === 0) {
      newErrors.documents = 'Required documents must be uploaded';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitMessage('');

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== 'documents') {
        formDataToSend.append(key, value);
      }
    });

    if (formData.documents) {
      Array.from(formData.documents).forEach((file) => {
        formDataToSend.append('documents', file);
      });
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/carrier-setup`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        setSubmitMessage('Application submitted successfully! We will contact you soon.');
        setFormData({
          companyName: '',
          dba: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          phone: '',
          email: '',
          mc: '',
          usdot: '',
          feinSsn: '',
          numberOfTrucks: '',
          numberOfDrivers: '',
          factorInvoices: 'no',
          preferredStates: '',
          documents: null
        });
      }
    } catch (error) {
      setSubmitMessage('There was an error submitting your application. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData(prev => ({ ...prev, documents: e.target.files }));
    }
  };

  const inputClasses = "w-full bg-white/10 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-gray-400 focus:border-white/30 focus:ring-0";
  const labelClasses = "block text-sm font-medium text-gray-300 mb-2";
  const errorClasses = "mt-1 text-sm text-red-500";

  return (
    <div className="bg-gradient-to-b from-[#1B3160] to-[#0F1E3C] min-h-screen py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Carrier Setup Form
          </h1>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Please complete the form below to begin the carrier setup process with Princeton Transport LLC.
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="bg-[#1B3160] rounded-xl p-8 border border-white/10">
            <form onSubmit={handleSubmit} className="space-y-6">

                        {/* Business Information */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="mc" className={labelClasses}>MC#*</label>
                  <input
                    type="text"
                    id="mc"
                    name="mc"
                    value={formData.mc}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.mc ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.mc && <p className={errorClasses}>{errors.mc}</p>}
                </div>
                <div>
                  <label htmlFor="usdot" className={labelClasses}>USDOT#*</label>
                  <input
                    type="text"
                    id="usdot"
                    name="usdot"
                    value={formData.usdot}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.usdot ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.usdot && <p className={errorClasses}>{errors.usdot}</p>}
                </div>
                <div>
                  <label htmlFor="feinSsn" className={labelClasses}>FEIN/SSN*</label>
                  <input
                    type="text"
                    id="feinSsn"
                    name="feinSsn"
                    value={formData.feinSsn}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.feinSsn ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.feinSsn && <p className={errorClasses}>{errors.feinSsn}</p>}
                </div>
              </div>

              {/* Company Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="companyName" className={labelClasses}>Company Name*</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.companyName ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.companyName && <p className={errorClasses}>{errors.companyName}</p>}
                </div>
                <div>
                  <label htmlFor="dba" className={labelClasses}>DBA (if any)</label>
                  <input
                    type="text"
                    id="dba"
                    name="dba"
                    value={formData.dba}
                    onChange={handleChange}
                    className={inputClasses}
                  />
                </div>
              </div>

              {/* Address Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="md:col-span-2">
                  <label htmlFor="address" className={labelClasses}>Address*</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="city" className={labelClasses}>City*</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="state" className={labelClasses}>State*</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="zipCode" className={labelClasses}>ZIP Code*</label>
                  <input
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                  />
                </div>
              </div>

              {/* Contact Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="phone" className={labelClasses}>Phone*</label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.phone ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.phone && <p className={errorClasses}>{errors.phone}</p>}
                </div>
                <div>
                  <label htmlFor="email" className={labelClasses}>Email*</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`${inputClasses} ${errors.email ? 'border-red-500' : ''}`}
                    required
                  />
                  {errors.email && <p className={errorClasses}>{errors.email}</p>}
                </div>
              </div>

    

              {/* Fleet Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="numberOfTrucks" className={labelClasses}>Number of Trucks*</label>
                  <input
                    type="number"
                    id="numberOfTrucks"
                    name="numberOfTrucks"
                    value={formData.numberOfTrucks}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                    min="1"
                  />
                </div>
                <div>
                  <label htmlFor="numberOfDrivers" className={labelClasses}>Number of Drivers*</label>
                  <input
                    type="number"
                    id="numberOfDrivers"
                    name="numberOfDrivers"
                    value={formData.numberOfDrivers}
                    onChange={handleChange}
                    className={inputClasses}
                    required
                    min="1"
                  />
                </div>
              </div>

              {/* Invoice Factoring */}
              <div>
                <label className={labelClasses}>Do you factor your invoices?*</label>
                <div className="flex space-x-4">
                  <label className="flex items-center space-x-2 text-gray-300">
                    <input
                      type="radio"
                      name="factorInvoices"
                      value="yes"
                      checked={formData.factorInvoices === 'yes'}
                      onChange={handleChange}
                      className="text-[#1B3160] focus:ring-0"
                    />
                    <span>Yes</span>
                  </label>
                  <label className="flex items-center space-x-2 text-gray-300">
                    <input
                      type="radio"
                      name="factorInvoices"
                      value="no"
                      checked={formData.factorInvoices === 'no'}
                      onChange={handleChange}
                      className="text-[#1B3160] focus:ring-0"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>

              {/* Preferred States */}
              <div>
                <label htmlFor="preferredStates" className={labelClasses}>What States Do You Prefer To Drive?*</label>
                <textarea
                  id="preferredStates"
                  name="preferredStates"
                  value={formData.preferredStates}
                  onChange={handleChange}
                  rows={3}
                  className={inputClasses}
                  required
                  placeholder="List the states you prefer to operate in"
                />
              </div>

              {/* Document Upload */}
              <div>
                <label className={labelClasses}>
                  Upload MC Authority Letter, Certificate Of Liability Insurance, W9*
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  accept=".pdf,.jpg,.jpeg,.png"
                  className="block w-full text-gray-300 file:mr-4 file:py-2 file:px-4
                    file:rounded-lg file:border-0 file:text-sm file:font-semibold
                    file:bg-white file:text-[#1B3160] hover:file:bg-gray-100
                    file:cursor-pointer cursor-pointer"
                  required
                />
                <p className="mt-1 text-sm text-gray-400">
                  You can select multiple files (PDFs and images)
                </p>
                {errors.documents && <p className={errorClasses}>{errors.documents}</p>}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-white text-[#1B3160] py-3 px-6 rounded-lg font-semibold
                  hover:bg-gray-100 transition-colors duration-200 disabled:bg-gray-300"
              >
                {isSubmitting ? 'Submitting...' : 'Submit Application'}
              </button>
            </form>

            {submitMessage && (
              <div className={`mt-4 p-4 rounded-lg ${
                submitMessage.includes('error')
                  ? 'bg-red-900/50 text-red-200 border border-red-700'
                  : 'bg-green-900/50 text-green-200 border border-green-700'
              }`}>
                {submitMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierSetup;
