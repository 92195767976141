// src/App.tsx
import React, {  lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { getAuth, onAuthStateChanged, User } from "firebase/auth";
// import { auth } from './config/firebase';
import Navigation from "./components/Navigation";
import Footer from "./templates/Footer";
import ScrollToTop from "./components/ScrollToTop";
import LoadingSpinner from "./components/LoadingSpinner";
import CarrierSetup from "./pages/CarrierSetup";
// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const DriverSetup = lazy(() => import("./pages/DriverSetup"));

export type SectionName = "home" | "about" | "services" | "contact" | "testimonials" | "careers" | "privacy" | "driver-setup" | "login" | "register" | "dashboard";





function App() {
  const sectionRefs = React.useRef<{ [key in SectionName]: HTMLElement | null }>({
    home: null,
    about: null,
    services: null,
    careers: null,
    contact: null,
    privacy: null,
    "driver-setup": null,
    login: null,
    register: null,
    dashboard: null,
    testimonials: null,
  });

  const scrollToSection = React.useCallback((section: SectionName) => {
    const element = sectionRefs.current[section];
    if (element) {
      const offsetTop = 63; // Adjust this value as needed
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offsetTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);


  return (
      <BrowserRouter>
        <div className="overflow-hidden pt-16">
          <Navigation scrollToSection={scrollToSection} />
          <main className="flex-grow min-h-screen">
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<Home sectionRefs={sectionRefs} scrollToSection={scrollToSection} />} />
                <Route path="/driver-setup" element={<CarrierSetup />} />
              </Routes>
            </Suspense>
          </main>
          <ScrollToTop />
          <Suspense fallback={<FooterSkeleton />}>
            <Footer scrollToSection={scrollToSection} />
          </Suspense>
        </div>
      </BrowserRouter>
  );
}

export default App;


const FooterSkeleton = () => <div className="h-40 bg-gray-200"></div>;
