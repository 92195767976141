import React, { FormEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SectionName } from '../App';
import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import Notification from '../components/Notification';

interface FooterProps {
  scrollToSection: (section: SectionName) => void;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
}

const Footer: React.FC<FooterProps> = ({ scrollToSection }) => {
  const currentYear = new Date().getFullYear();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');
    setIsVisible(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/newsletter/subscribe`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        setSubmitMessage('Thank you for subscribing!');
        setIsVisible(true);
        setFormData({ name: '', email: '', phone: '' });
      } else {
        setSubmitMessage('An error occurred. Please try again.');
        setIsVisible(true);
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setSubmitMessage('An error occurred. Please try again.');
      setIsVisible(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const navItems: SectionName[] = [
    'home',
    'about',
    'services',
    'contact',
    'testimonials',
    'careers',
  ];

  return (
    <footer className="bg-gradient-to-b from-[#1B3160] to-[#0F1E3C] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Logo and Info */}
          <div>
            <NavLink to="/" className="block mb-4">
              <img src="logo.png" alt="Princeton Transport LLC" className="max-w-[200px]" />
            </NavLink>
            <p className="text-gray-300 mt-2">Your trusted transportation partner</p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {navItems.map((link) => (
                <li key={link}>
                  <button
                    onClick={() => scrollToSection(link)}
                    className="text-gray-300 hover:text-white transition-colors duration-200"
                  >
                    {link.charAt(0).toUpperCase() + link.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <EnvelopeIcon className="w-5 h-5 text-gray-300" />
                <span className="text-gray-300">info@princetontransport.com</span>
              </li>
              <li className="flex items-center space-x-3">
                <PhoneIcon className="w-5 h-5 text-gray-300" />
                <span className="text-gray-300">(971) 206-9096</span>
              </li>
              <li className="flex items-start space-x-3">
                <MapPinIcon className="w-5 h-5 text-gray-300 mt-1" />
                <span className="text-gray-300">
                  16792 NW DESERT CANYON DR
                  <br />
                  BEAVERTON, OR 97006
                </span>
              </li>
            </ul>
          </div>

          {/* Newsletter Signup */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
            <form className="space-y-2" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Your name"
                className="w-full px-3 py-2 bg-white/10 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:border-white/30 focus:ring-0"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Your email"
                className="w-full px-3 py-2 bg-white/10 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:border-white/30 focus:ring-0"
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Your phone number"
                className="w-full px-3 py-2 bg-white/10 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:border-white/30 focus:ring-0"
              />
              <button
                type="submit"
                className="w-full bg-white text-[#1B3160] font-semibold py-2 px-4 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>

        {submitMessage && (
          <Notification
            message={submitMessage}
            isVisible={isVisible}
            onClose={() => {
              setIsVisible(false);
              setSubmitMessage('');
            }}
            type={submitMessage.includes('success') ? 'success' : 'error'}
          />
        )}

        {/* Bottom bar */}
        <div className="mt-8 pt-8 border-t border-white/10 text-center">
          <p className="text-gray-300">
            &copy; {currentYear} Princeton Transport LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
