import React from 'react';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1B3160] to-[#0F1E3C] flex items-center justify-center">
      <div className="relative">
        {/* Outer ring */}
        <div className="absolute inset-0 rounded-full border-4 border-white/10"></div>
        
        {/* Spinning truck silhouette */}
        <div className="w-24 h-24 animate-spin">
          <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            className="w-full h-full text-white"
          >
            <path 
              d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" 
              fill="currentColor"
            />
          </svg>
        </div>

        {/* Loading text */}
        <div className="mt-8 text-center">
          <div className="text-white font-semibold">Loading</div>
          <div className="flex justify-center mt-2 space-x-1">
            {[0, 1, 2].map((dot) => (
              <div
                key={dot}
                className="w-2 h-2 bg-white rounded-full animate-bounce"
                style={{
                  animationDelay: `${dot * 0.2}s`,
                  animationDuration: '1s'
                }}
              ></div>
            ))}
          </div>
        </div>

        {/* Decorative elements */}
        <div className="absolute -top-12 -left-12 w-24 h-24 bg-white/5 rounded-full blur-xl"></div>
        <div className="absolute -bottom-8 -right-8 w-16 h-16 bg-white/5 rounded-full blur-lg"></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;